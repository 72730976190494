import React from 'react';
import Reveal from 'react-reveal/Reveal';
import Serviceitem from '../../components/Service/Software/ServiceItem';
import Languages from '../../components/Service/Software/Languages';
import Scratch from '../../components/Service/Software/fromScratch';
import Rebuild from '../../components/Service/Software/Rebuild';
import serviceData from '../../data/softwareData';

const Software = () => (
  <div>
    <Languages />
    <Scratch />
    <Rebuild />
    <section className="bg_color sec_pad">
      <div className="container custom_container">
        <h2 className="f_size_30 f_600 t_color3 l_height40 text-center mb_90 wow fadeInUp" data-wow-delay="0.2s">What We Work With</h2>
        <div className="mb_30">
          {
            serviceData.map((items) => (
              <Reveal effect="fadeInUp" key={items.text}>
                <div>
                  <Serviceitem
                    iShap={items.shape}
                    icon={items.icon}
                    text={items.text}
                    description={items.description}
                  />
                </div>
              </Reveal>
            ))
          }
        </div>
      </div>
    </section>
  </div>
);
export default Software;

import React, { Component } from 'react';

class ServiceItem extends Component {
  render() {
    const { text, description } = this.props;
    return (
      <div className="p_service_item agency_service_item pr_70 wow fadeInUp row">
        {/* <div className="icon col-auto">
                    <img src={require ("../../../img/home4/" + icon)} alt=""/>
                </div> */}
        <div className="col">
          <h5 className="f_600 f_p t_color3">{text}</h5>
          <p>{description}</p>
        </div>
      </div>
    );
  }
}
export default ServiceItem;

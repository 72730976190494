export default [
  {
    text: 'Chatbots / Natural Language Understanding',
    description: 'We have built chatbots across all platforms and are among the leading national experts on Google\'s Dialogflow. Consumers today expect their needs to be met instantaneously. Chatbots are the best way to meet this demand while delivering a personalized experience that is on brand. However, poorly designed chatbots don’t understand what is being asked of them, don’t escalate appropriately, and frequently lead consumers in frustrating circles. Work with us to ensure your investment keeps your clients smiling and buying. We’ll utilize leading methodologies in Natural Language Understanding (NLU) to ensure your chatbot understands the nuances of human communication and performs the proper commands according to context. We can then work with you on the graphics to sculpt the perfect brand-enhancing bot.',
    icon: 'icon01.png',
  },
  {
    text: 'Interactive Websites',
    description: 'Complex single page web apps and modern javascript make us happy! Our developers have extensive history working with pixel perfection and performance. Animations, pulldowns, drag bars, curated content, video streaming, live streams, visualizations – these are just some of the things we can help you bring to life. OPNA is founded on human-oriented principles and we love improving the ways that humans and technology interface.',
    icon: 'icon02.png',
  },
  {
    text: 'Mobile Apps',
    description: 'Our apps are in pockets around the world! Having crafted apps for the largest tech companies in the world we know how to deliver sleek, performant, user-friendly apps for both iOS and Android that drive and inspire brand awareness. We utilize a comprehensive methodology to ensure your app can get to market quickly and scale with you while hitting your deployment schedule.',
    icon: 'icon3.png',
  },
  {
    text: 'IoT/Hardware Interface',
    description: 'Make your clients happy by getting your products talking. Whether you’re gathering sensor information from global inputs or hoping to teach your newest robot how to mix a mean martini – we’re the team to help you pull it all together. Our combined experience with software, hardware, and machine automation means that you can count on us to deliver intricate programs that enable your business to step up to the next level.',
    icon: 'icon_04.png',
  },
  {
    text: 'APIs and Backend Integration',
    description: 'When configuring your ecosystem it’s crucial to get lead engineers that understand the importance of your business objectives and the nuances of your custom and out of the box solutions. We’ll get your platforms talking so that your organization runs as an integrated whole.',
    icon: 'icon_05.png',
  },
  {
    text: 'Big Data',
    description: 'Got data, need processing? We can create robust systems that can take a firehose of data and remain standing. No matter how complex your data needs are, you’ll have peace of mind knowing our reliable systems are giving you accurate data and empowering your business decisions. Show us what platforms you already have and what data gaps you need filled and we’ll get you the big picture in no time.',
    icon: 'icon_shape1.png',
  },
];

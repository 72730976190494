import React from 'react';
import Reveal from 'react-reveal';
import { Link } from 'gatsby';
import urls from '../../../data/urls';
import LanguagesList from './LangaugesList';

const langaugeListData = [
  {
    title: 'Front End Frameworks (Javascript / Typescript):',
    languages: 'React Native, React, Gatsby, Ember, Angular, Mobx, Redux',
  }, {
    title: 'Other Front End',
    languages: 'CSS / Sass, HTML5, WordPress',
  }, {
    title: 'Back End Frameworks:',
    languages: 'Python - Flask, Django. Node.js - Express, GraphQL. Java',
  }, {
    title: 'Other Back End:',
    languages: 'NGINX, PHP, SQL, NoSQL, Dialogflow',
  }, {
    title: 'Cloud Hosting:',
    languages: 'AWS, GCP, Terraform, Docker, Kubernetes',
  }, {
    title: 'Testing / Continuous Integration and Deployment:',
    languages: 'Mocha, Karma, Jest, Qunit, CircleCI, Code Climate, Jenkins',
  }, {
    title: 'Hardware Prototyping / Embedded:',
    languages: 'C++, Arduino, Raspberry Pi',
  },
]

const Languages = () => (
  <section className="sec_pad">
    <div className="triangle_shape" />
    <div className="container">
      <div className="row">
        <div className="d-flex align-items-center">
          <Reveal bottom cascade>
            <div>

              <h2 className="f_p f_size_30 f_700 t_color3 l_height45 mb-30">We Speak Your Language</h2>
              <p className="f_400">We have worked in almost every programming language and always love to learn more. Below is a list of our current favorites but technology is always changing and we like to evolve with it!</p>
              <div>
                <LanguagesList>
                  {langaugeListData.map((props) => (
                    <LanguagesList.Item {...props} />
                  ))}
                </LanguagesList>
              </div>
              <Link to={urls.contactUs} className="app_btn btn_hover mt_40">Get A Quote!</Link>
            </div>
          </Reveal>

        </div>
      </div>
    </div>
  </section>
);
export default Languages;

import React from 'react';
import Reveal from 'react-reveal';
import { Link, graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import urls from '../../../data/urls';

const AppFeatures = () => {
  const imgData = useStaticQuery(graphql`
    query {
      contentfulAsset(title: {eq: "services-software-rework"}) {
        fluid {
          aspectRatio
          base64
          sizes
          src
          srcSet
          srcSetWebp
          srcWebp
        }
      }
    }
  `);
  const image = imgData.contentfulAsset.fluid;

  return (
    <section className="sec_pad">
      <div className="triangle_shape" />
      <div className="container">
        <div className="row">
          <div className="col-lg-5 d-flex align-items-center">
            <Img fluid={image} className="w-75 mx-auto" />
          </div>
          <div className="col-lg-7 d-flex align-items-center">
            <Reveal bottom cascade>
              <div className="app_featured_content">
                <h2 className="f_p f_size_30 f_700 t_color3 l_height45 mb-30">
                  Build It Better
                </h2>
                <Link to={urls.contactUs} className="app_btn btn_hover mt_40">Get A Quote!</Link>
                <p className="f_400">Modernize your product, transition your team to a new technology or troubleshoot performance issues with OPNA. We will work closely with you to create lasting solutions and help your team build the skills they need to maintain this transition after our work is done. We can audit your ecosystem and product, help identify areas for improvement, select the technologies best suited to your needs, and implement solutions that help you reach your business and technology goals. Depending on the size of your ecosystem we might deploy a single Project Manager or a larger team under the direction of one of our co-founders. Let us know what you’re working with and we’ll get you a cost estimate and timeline straight away.</p>
              </div>
            </Reveal>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AppFeatures;

import React from 'react';
import Img from 'gatsby-image';
import Reveal from 'react-reveal';
import { graphql, useStaticQuery } from 'gatsby';

const Scratch = () => {
  const imgData = useStaticQuery(graphql`
    query {
      contentfulAsset(title: {eq: "services-software-scratch"}) {
        fluid {
          aspectRatio
          base64
          sizes
          src
          srcSet
          srcSetWebp
          srcWebp
        }
      }
    }
  `);
  const image = imgData.contentfulAsset.fluid;

  return (
    <section className="sec_pad" id="features">
      <div className="container">
        <div className="row flex-row-reverse app_feature_info">
          <div className="col-lg-6 d-flex align-items-center">
            <Img fluid={image} className="w-75 mx-auto" />
          </div>
          <div className="col-lg-6">
            <Reveal bottom cascade>
              <div className="app_featured_content">
                <h2 className="f_p f_size_30 f_700 t_color3 l_height45 pr_70 mb-30">Build it from scratch</h2>
                <p className="f_400">You dream it, we build it. Need to add interactivity to your website or connect and manage IoT / smart technology? Whatever you’re working on, our expertise and leadership will help you prototype, quality test, and deliver impressive solutions your users will love.</p>
              </div>
            </Reveal>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Scratch;

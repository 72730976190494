import React from 'react';
import Noop from '../../../util/Noop';
import './LanguagesList.scss';

const LanguagesList = ({ children }) => ((
  <ul className="languages-list">
    {
      React.Children.map(children, (child, index) => {
        const {
          title,
          languages,
        } = child.props;

        return (
          <li key={index} className="languages-list__item">
            <h5>{title}</h5>
            <p>{languages}</p>
          </li>
        );
      })
    }
  </ul>
));

LanguagesList.Item = Noop;

export default LanguagesList;
